import React from 'react';

// Admin Imports
import MainDashboard from 'views/admin/default';
import Profile from 'views/admin/profile';
import Orders from 'views/admin/orders';
import Drafts from 'views/admin/orders/drafts';
import Canceled from 'views/admin/orders/canceled';
import Completed from 'views/admin/orders/completed';
import Client from 'views/admin/orders/client';
import Agent from 'views/admin/orders/agent';
import New from 'views/admin/orders/new';
import Order from 'views/admin/order';
import Chat from 'views/admin/chat';
import Organizations from 'views/admin/organizations';

// Auth Imports
import SignIn from 'views/auth/signIn';
import Register from 'views/auth/register';
import Password from 'views/auth/passwordRecovery';
import Confirm from 'views/auth/confirm';


const routes = [
  {
    name: 'Рабочий кабинет',
    layout: '/admin',
    path: '/default',
    component: <MainDashboard />,
  },
  {
    name: 'Заявки в работе',
    layout: '/admin',
    path: '/orders',
    component: <Orders />,
  },
  {
    name: 'Черновики',
    layout: '/admin',
    path: '/orders/drafts',
    component: <Drafts />,
  },
  {
    name: 'Новые',
    layout: '/admin',
    path: '/orders/new',
    component: <New />,
  },
  {
    name: 'Отмененные',
    layout: '/admin',
    path: '/orders/canceled',
    component: <Canceled />,
  },
  {
    name: 'Клиент',
    layout: '/admin',
    path: '/orders/client',
    component: <Client />,
  },
  {
    name: 'Агент',
    layout: '/admin',
    path: '/orders/agent',
    component: <Agent />,
  },
  {
    name: 'Завершенные',
    layout: '/admin',
    path: '/orders/completed',
    component: <Completed />,
  },
  {
    name: 'Личный кабинет',
    layout: '/admin',
    path: '/profile',
    component: <Profile />,
  },
  {
    name: 'Организации',
    layout: '/admin',
    path: '/organizations',
    component: <Organizations />,
  },
  {
    name: 'Детали заявки',
    layout: '/admin',
    path: '/orders/:id',
    component: <Order />,
  },
  {
    name: 'Войти',
    layout: '/auth',
    path: '/sign-in',
    component: <SignIn />,
  },
  {
    name: 'Зарегистрироваться',
    layout: '/auth',
    path: '/register',
    component: <Register />,
  },
  {
    name: 'Восстановление пароля',
    layout: '/auth',
    path: '/password-recovery',
    component: <Password />,
  },
  {
    name: 'Подтвержление регистрации',
    layout: '/auth',
    path: '/confirm/:token',
    component: <Confirm />,
  },
  {
    name: 'Чат',
    layout: '/admin',
    path: '/chat',
    component: <Chat />,
  },

];

export default routes;
