import React, { useState, useContext, useEffect } from 'react';
import { Text, useColorModeValue, Box, Icon, Flex, Button, IconButton, HStack, Modal, ModalOverlay, ModalContent, ModalCloseButton, ModalBody, ModalFooter, useDisclosure, Tooltip } from '@chakra-ui/react';
import { fetchChatByOrderId } from "contexts/ChatContext";
import AgreementTemplate from 'views/admin/order/documents/AgreementTemplate';
import FileUpload from "views/admin/profile/components/Upload";
import MessageInput from "views/admin/profile/components/LonelyInput";
import { FaDownload, FaEye, FaCheck, FaEdit } from 'react-icons/fa';
import ReactDOMServer from 'react-dom/server';
import { DataContext } from "contexts/DataContext";
import { MdDescription, MdArrowDownward } from "react-icons/md";

const Agreement = ({ user, order, updateStatus }) => {
  const [currentMode, setCurrentMode] = useState(null);
  const [chat, setChat] = useState(null); // Информация о чате
  const [isDogUploaded, setIsDogUploaded] = useState(false);
  const [formData, setFormData] = useState(order);
  const { updateOrder } = useContext(DataContext); 

  useEffect(() => {
    const fetchChat = async () => {
      try {
        const chatData = await fetchChatByOrderId(order.id); // Используем fetchChatByOrderId
        if (!chatData) {
          setChat(null);
        } else {
          setChat(chatData);
        }
      } catch (err) {
        console.error(err);
      } finally {
      }
    };
    fetchChat();
  }, [order.id]);

  const textColorPrimary = useColorModeValue("secondaryGray.900", "white");
  const { isOpen, onOpen, onClose } = useDisclosure();
  const dogFileUrl = `http://5.63.158.62:3000/uploads/others/` +  formData.dog;
  
  const handleAproveClick = () => {
    handleSaveAgreement('согласование стоимости перевода');
  };


  const handleSaveAgreement = async (status) => {
    if (!order || !order.id) {
      console.error('ID заказа отсутствует');
      return;
    }

    try {
      await updateOrder(order.id, formData);
      updateStatus(status);
      console.log('Договор сохранён и статус обновлён!');
    } catch (error) {
      console.error('Ошибка сохранения:', error);
    }
  };

  const handleDogUploadComplete = (fieldName, fileName) => {
    setFormData((prevData) => ({
      ...prevData,
      [fieldName]: fileName,
    }));
    setIsDogUploaded(true);
  };

  const cssStyles = `
  .container {
    font-family: Arial, sans-serif;
    max-width: 1000px;
    margin: 0 auto;
    padding: 20px;
    line-height: 1.6;
    color: #333;
  }
  .title {
    font-family: Arial, sans-serif;
    text-align: center;
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 10px;
    text-transform: uppercase;
  }
  .sectionTitle {
    font-family: Arial, sans-serif;
    font-size: 16px;
    font-weight: bold;
    margin-top: 20px;
    margin-bottom: 10px;
  }
  .subSectionTitle {
    font-family: Arial, sans-serif;
    font-size: 15px;
    font-weight: bold;
    margin-top: 15px;
    margin-bottom: 5px;
  }
  .paragraph {
    font-family: Arial, sans-serif;
    text-align: left;
    font-size: 15px;
    margin-bottom: 10px;
  }
  .listItem {
    font-family: Arial, sans-serif;
    font-size: 15px;
    margin-bottom: 5px;
  }
  .table {
    width: 100%;
    text-align: left;
    border-collapse: collapse;
  }
  .signaturesItem {
    width: 50%;
    vertical-align: top;
    padding: 0 10px;
  }

`;


  const handleDownload = () => {
    const content = ReactDOMServer.renderToStaticMarkup(<AgreementTemplate order={order} />);
    const element = document.createElement("a");
    const fileContent = `
      <html xmlns:o='urn:schemas-microsoft-com:office:office' xmlns:w='urn:schemas-microsoft-com:office:word' xmlns='http://www.w3.org/TR/REC-html40'>
        <head>
          <meta charset='utf-8'>
          <title>Документ</title>
          <style>${cssStyles}</style>
        </head>
        <body>${content}</body>
      </html>`;
    const blob = new Blob(['\ufeff', fileContent], { type: 'application/msword' });
    const url = URL.createObjectURL(blob);
    element.href = url;
    element.download = "agreement_template.doc";
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
  };

  return (
    <Flex direction="column" align="center">
      {!formData.dog && (
    <>
      <Box
        position="relative"
        h="600px"
        w="95%"
        overflowY="auto"
        borderWidth="1px"
        borderRadius="md"
        ml="auto"
        mr="auto"
        p={3}
        pt={8}
        mb={4}
        _hover={{
          '> .icon-buttons': { opacity: 1, visibility: 'visible' }
        }}
      >
        <Box className="icon-buttons" position="absolute" top="0" right="0" p={2} opacity={0} visibility="hidden" transition="opacity 0.2s, visibility 0.2s">
          <HStack spacing={2}>
            <Tooltip label="Скачать" bg="blue.600" fontSize="sm" borderRadius="md">
              <IconButton icon={<FaDownload />} aria-label="Скачать" onClick={handleDownload} />
            </Tooltip>
            <Tooltip label="Посмотреть" bg="blue.600" fontSize="sm" borderRadius="md">
              <IconButton icon={<FaEye />} aria-label="Посмотреть" onClick={onOpen} />
            </Tooltip>
          </HStack>
        </Box>
        <AgreementTemplate order={order} />
      </Box>

      <Box m="0 auto 20px">
          <HStack spacing={2}>
            <Tooltip label="Скачать" bg="blue.600" fontSize="sm" borderRadius="md">
              <IconButton icon={<FaDownload />} aria-label="Скачать" onClick={handleDownload} />
            </Tooltip>
            <Tooltip label="Посмотреть" bg="blue.600" fontSize="sm" borderRadius="md">
              <IconButton icon={<FaEye />} aria-label="Посмотреть" onClick={onOpen} />
            </Tooltip>
          </HStack>
        </Box>
        </>
 )}
        {formData.dog && (
          <>
                  <Flex
                  align="center"
                  bg="gray.100"
                  p={4}
                  borderRadius="lg"
                  boxShadow="sm"
                  width="100%"
                  mb="5"
                >
                  <Icon as={MdDescription} color="gray.500" w={10} h={10} mr={4} />
                  <Box>
                    <Text color={textColorPrimary} fontWeight="bold" fontSize="md" mb={2}>
                      Первоначальная версия договора
                    </Text>
                    <Flex>
                <Button
                  onClick={handleDownload}
                  mr={1}
                  download={order.dog}
                  size="xs"
                  colorScheme="blue"
                >
                  <Icon as={MdArrowDownward} />
                  Скачать
                </Button>
              </Flex>
                  </Box>
                </Flex>
        <Flex
          align="center"
          bg="gray.100"
          p={4}
          borderRadius="lg"
          boxShadow="sm"
          width="100%"
          mb="5"
        >
          <Icon as={MdDescription} color="gray.500" w={10} h={10} mr={4} />
          <Box>
            <Text color={textColorPrimary} fontWeight="bold" fontSize="md" mb={2}>
              Актуальная версия договора
            </Text>
            <Flex>
        <Button
          as="a"
          href={dogFileUrl}
          mr={1}
          download={order.dog}
          size="xs"
          colorScheme="blue"
        >
          <Icon as={MdArrowDownward} />
          Скачать
        </Button>
      </Flex>
          </Box>
        </Flex>
        </>
    )}
        <Flex direction="column" align="center">
      {/* Управление режимами */}
      <Flex gap={4}>
        <Button
          leftIcon={<FaEdit />}
          colorScheme="gray"
          variant="outline"
          width="150px"
          onClick={() => setCurrentMode("edit")}
        >
          Изменить
        </Button>
        <Button
          leftIcon={<FaCheck />}
          colorScheme="blue"
          width="150px"
          onClick={() => setCurrentMode("approve")}
        >
          Согласовать
        </Button>
      </Flex>

      {/* Режим работы */}
      {currentMode && (
        <Box maxWidth="570px">
                    {currentMode === "edit" && (
            <>
            <Text
            color={textColorPrimary}
            fontSize="sm"
            fontWeight="bold"
            textAlign="center"
            mt={10}
            mb={4}
          >
            Отправьте свой комментарий
          </Text>
          <MessageInput chatId={chat.id} userId={user.client_id} userName={user.name} />
          </>
        )} 
          <Text
            color={textColorPrimary}
            fontSize="sm"
            fontWeight="bold"
            textAlign="center"
            mt={7}
            mb={4}
          >
            {currentMode === "approve"
              ? "Загрузите подписанную версию договора"
              : "Приложите свою версию договора"}
          </Text>

          <FileUpload
            setIsFileUploaded={setIsDogUploaded}
            uploadType="dog"
            onFileUploaded={(dogFileName) => handleDogUploadComplete("dog", dogFileName)}
          />
{user.client_type === "agent" ? (
          <Button
            colorScheme="blue"
            width="100%"
            onClick={() =>
              currentMode === "approve"
                ? handleSaveAgreement("согласование стоимости перевода")
                : handleSaveAgreement("требуется доработка")
            }
            isDisabled={!isDogUploaded}
            mt={1}
            mb={3}
            borderRadius="0.375rem"
          >
            {currentMode === "approve"
              ? "Отправить и перейти к платежному поручению"
              : "Отправить"}
          </Button>
  ) : (
    <Button
    colorScheme="blue"
    width="100%"
    onClick={() =>
      handleSaveAgreement(
        "договор на согласовании у агента"
      )
    }
    isDisabled={!isDogUploaded}
    mt={1}
    mb={3}
    borderRadius="0.375rem"
  >
    {currentMode === "approve"
      ? "Отправить на согласование"
      : "Отправить"}
  </Button>

            )}

          </Box>
      )}
    </Flex>





      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent width="90%" maxWidth="900px" pt={8}>
          <ModalCloseButton />
          <ModalBody>
            <AgreementTemplate order={order} />
          </ModalBody>
          <ModalFooter>
          <Button colorScheme="blue" mr={3} onClick={handleDownload}>Скачать</Button>
            <Button colorScheme="gray" variant="outline" mr={3} onClick={onClose}>Закрыть</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Flex>
  );
};

export default Agreement;
