import React, { useState } from 'react';
import { Flex, Box, Button, Text, Circle, SliderMark, Textarea, Slider, VStack, SliderTrack, SliderThumb, SliderFilledTrack, Image, Icon, HStack, Input, Modal, ModalOverlay, useDisclosure, useColorModeValue, ModalContent, ModalCloseButton, ModalBody, ModalFooter, Tooltip, IconButton } from '@chakra-ui/react';
import TransferTemplate from 'views/admin/order/documents/TransferTemplate';
import { FaFileDownload, FaFileUpload, FaCheckCircle, FaDownload, FaEye, FaCheck, FaEdit } from 'react-icons/fa';
import { HiOutlineBanknotes } from "react-icons/hi2";
import { BsBank } from "react-icons/bs";
import FileUpload from "views/admin/profile/components/Upload";
import ReactDOMServer from 'react-dom/server';
import myImage from './1.jpg';
import { ArrowUpIcon } from '@chakra-ui/icons';

const TransferInstruction = ({ order, user, updateStatus }) => {
  const [activeStep, setActiveStep] = useState(1);
  const devideAmount = order.currency === 'RUB' ? order.payment_amount / 100 : order.payment_amount;
  const multiplyAmount = order.currency === 'RUB' ? order.payment_amount : order.payment_amount * 100;
  const nextCurrency = order.currency === 'RUB' ? 'USD' : 'RUB';
  const comission = order.currency === 'RUB' ? order.payment_amount / 100 * 3.5 : order.payment_amount * 3.5;
  const total = order.currency === 'RUB' ? order.payment_amount + order.payment_amount / 100 * 3.5 : order.payment_amount * 100 + order.payment_amount * 3.5;
  const [isEditVisible, setIsEditVisible] = useState(false);
  const [isApproveVisible, setIsApproveVisible] = useState(false);
  const [isFileUploaded, setIsFileUploaded] = useState(false);
  const textColorPrimary = useColorModeValue("secondaryGray.900", "white");
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [currency, setCurrency] = useState('USD'); // Состояние для валюты
  const [amount, setAmount] = useState(''); // Состояние для суммы
  const [stepTop, setStepTop] = useState(1);
  const [sliderValue, setSliderValue] = useState(50000); // Значение ползунка
  const [commission, setCommission] = useState(3.3); // Комиссия
  const [comment, setComment] = useState(''); // Комментарий

  // Обновление комиссии на основе значения ползунка
  const handleSliderChange = (value) => {
    setSliderValue(value);
    // Пример расчета комиссии (можно настроить логику)
    const calculatedCommission = value < 250000 ? 3.5 : value < 500000 ? 3.3 : 3.0;
    setCommission(calculatedCommission);
  };



  const handleCurrencyChange = (newCurrency) => {
    setCurrency(newCurrency);
  };

  const handleStep = (step) => {
    setStepTop(step);
  };

  const displayedAmount = currency === 'USD' ? devideAmount : order.payment_amount;


  const handleNextStep = () => {
    if (activeStep < 4) {
      setActiveStep((prev) => prev + 1);
    }
  };

  const handleEditClick = () => {
    setIsEditVisible(true);
    setIsApproveVisible(false);
  };
  
  const handleApproveClick = () => {
    setIsApproveVisible(true);
    setIsEditVisible(false);
  };

  const handleSubmitClick = () => {
  
  };

  const handleDownloadTransfer = () => {
    const content = ReactDOMServer.renderToStaticMarkup(<TransferTemplate order={order} user={user} />);
    const element = document.createElement("a");
    const fileContent = `
      <html xmlns:o='urn:schemas-microsoft-com:office:office' xmlns:w='urn:schemas-microsoft-com:office:word' xmlns='http://www.w3.org/TR/REC-html40'>
        <head>
          <meta charset='utf-8'>
          <title>Документ</title>
          <style>${cssStyles}</style>
        </head>
        <body>${content}</body>
      </html>`;
    const blob = new Blob(['\ufeff', fileContent], { type: 'application/msword' });
    const url = URL.createObjectURL(blob);
    element.href = url;
    element.download = "transfer.doc";
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
  };



  const cssStyles = `
  @page {
    size: landscape;
    margin: 1in; /* Устанавливает поля страницы */
  }
  .container {
    font-family: Arial, sans-serif;
    max-width: 1000px;
    margin: 0 auto;
    padding: 20px;
    line-height: 1.6;
    color: #333;
  }
  .title {
    font-family: Arial, sans-serif;
    text-align: center;
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 10px;
    text-transform: uppercase;
  }
  .sectionTitle {
    font-family: Arial, sans-serif;
    font-size: 16px;
    font-weight: bold;
    margin-top: 20px;
    margin-bottom: 10px;
  }
  .subSectionTitle {
    font-family: Arial, sans-serif;
    font-size: 15px;
    font-weight: bold;
    margin-top: 15px;
    margin-bottom: 5px;
  }
  .paragraph {
    font-family: Arial, sans-serif;
    text-align: left;
    font-size: 15px;
    margin-bottom: 10px;
  }
  .listItem {
    font-family: Arial, sans-serif;
    font-size: 15px;
    margin-bottom: 5px;
  }
  .table {
    width: 100%;
    text-align: left;
    border-collapse: collapse;
  }
  .signaturesItem {
    width: 50%;
    vertical-align: top;
    padding: 0 10px;
  }

`;

  const steps = [
    {
      id: 1,
      title: '1 шаг. Согласуйте стоимость перевода',
      content: (
        <Flex direction="column" alignItems="center">
<Flex bg="white" direction="column" justifyContent="center" borderRadius="md" p={4}>
      {/* Верхняя часть */}
      <VStack align="start" spacing={1} mb={4}>
        <Text fontSize="sm" fontWeight="bold"> Сумма платежа</Text>
<Text fontSize="xs" color="gray.500">Amount of payment</Text>
      </VStack>

      {/* Поле для суммы и кнопки валюты */}
      <Flex align="center" gap={4}>
        {/* Поле для отображения суммы */}
        <Box
          borderRadius="md"
          minWidth="240px"
          bg="white"
          height="70px"
          fontSize="40px"
          fontWeight="bold"
          borderWidth="1px"
          p="5px 10px"
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          {displayedAmount} {/* Динамическое отображение суммы */}
        </Box>

        {/* Кнопки валюты */}
        <Flex direction="column" borderRadius="md" borderWidth="1px" overflow="hidden">
          <Button
            size="sm"
            onClick={() => handleCurrencyChange('USD')}
            bg={currency === 'USD' ? 'gray.300' : 'white'}
            _hover={{ bg: 'gray.100' }}
            borderBottom="1px solid black"
            borderRadius="0"
            height="35px"
          >
            USD
          </Button>
          <Button
            size="sm"
            onClick={() => handleCurrencyChange('RUB')}
            bg={currency === 'RUB' ? 'gray.300' : 'white'}
            borderRadius="0"
            height="35px"
            _hover={{ bg: 'gray.100' }}
          >
            РУБ
          </Button>
        </Flex>
      </Flex>

      {/* Нижняя часть */}
      <Text mt={4} fontSize="sm" color="gray.600">
        Сумма счета (Invoice), которую Вам нужно оплатить
      </Text>
    </Flex>

    <Flex direction="row" align="flex-start" gap={6} p={4}>
      {/* Левая часть */}
      <VStack align="start" spacing={4}>
        {/* Сумма возмещения */}
        <Flex align="center" gap={4}>
          <Box
            borderWidth="1px"
            borderRadius="md"
            p="10px"
            minWidth="200px"
            textAlign="center"
          >
            <Text fontSize="sm" fontWeight="bold">
              Сумма возмещения
            </Text>
            <Text fontSize="lg">{ total } RUB</Text>
          </Box>
        </Flex>

        {/* Сумма комиссии */}
        <Flex align="center" gap={4}>
          <Box
            borderWidth="1px"
            borderRadius="md"
            p="10px"
            minWidth="200px"
            textAlign="center"
          >
            <Text fontSize="sm" fontWeight="bold">
              Сумма комиссии
            </Text>
            <Text fontSize="lg">{ comission }  RUB</Text>
          </Box>
        </Flex>
      </VStack>

      {/* Правая часть */}
      <VStack align="start" spacing={4} flex="1">
        {/* Информация о курсе и комиссии */}
        <Box borderWidth="1px" borderRadius="md" p={4} height="70px" textAlign="center">
          <Text fontSize="sm">Курс 1 RUB = 100 { order.currency }</Text>
          <Text fontSize="sm">Комиссия 3,5%</Text>
        </Box>

        {/* Кнопки */}
        <Flex direction="column" w="100%" gap={2}>
          <Button colorScheme="blue" size="sm" minWidth="100%" onClick={() => setStepTop(2)}>
            Изменить
          </Button>
          <Button colorScheme="green" size="sm" width="100%" onClick={handleNextStep}>
            Согласовать
          </Button>
        </Flex>
      </VStack>
    </Flex>
    {stepTop === 2 && ( 
    <Flex align="center" p={4} gap={6}>
      {/* Блок курса валют */}
      <Box bg="white" borderRadius="md" width="300px" p={4} textAlign="center">
        <Text fontSize="lg" fontWeight="bold">Курс 1 RUB = 100 { order.currency }</Text>
        <Button
          mt={2}
          colorScheme="blue"
          size="sm"
          onClick={() => setStepTop(3)}
        >
          Изменить
        </Button>
      </Box>

      {/* Блок комиссии */}
      <Box bg="white" borderRadius="md" width="300px" p={4} textAlign="center">
        <Text fontSize="lg" fontWeight="bold">Комиссия 3,5%</Text>
        <Button
          mt={2}
          colorScheme="blue"
          size="sm"
          onClick={() => setStepTop(5)}
        >
          Изменить
        </Button>
      </Box>
    </Flex>
     )}

{stepTop === 3 && ( 
<Flex direction="row" align="center" gap={6} p={4}>
      {/* Банк России */}
      <Box
        borderWidth="1px"
        borderRadius="md"
        width="250px"
        p={4}
        textAlign="center"
      >
        <Text fontSize="lg" fontWeight="bold">
          Банк России
        </Text>
        <Text fontSize="md" mt={2}>
          Курс USD1 = RUB100
        </Text>
        <Button
          mt={4}
          colorScheme="blue"
          size="sm"
          onClick={() => setStepTop(4)}
        >
          Выбрать
        </Button>
      </Box>

      {/* Investing.com */}
      <Box
        borderWidth="1px"
        borderRadius="md"
        width="250px"
        p={4}
        textAlign="center"
      >
        <Text fontSize="lg" fontWeight="bold">
          investing.com
        </Text>
        <Text fontSize="md" mt={2}>
          Курс USD1 = RUB100
        </Text>
        <Button
          mt={4}
          colorScheme="blue"
          size="sm"
          onClick={() => setStepTop(7)}
        >
          Выбрать
        </Button>
      </Box>
    </Flex>
  )}

{stepTop === 4 && ( 
<Flex direction="row" align="center" gap={4}>
        <Box>
          <Text fontSize="lg" fontWeight="bold" mb={2}>
            Напишите комментарий в чат
          </Text>
          <Textarea
            placeholder="Введите комментарий..."
            borderStyle="dashed"
            borderWidth="2px"
            width="400px"
            height="100px"
          />
        </Box>

        {/* Кнопка "Направить" */}
        <Button
          colorScheme="blue"
          size="md"
          alignSelf="flex-end"
          onClick={() => console.log(`Комментарий: , Банк: `)}
        >
          Направить
        </Button>
      </Flex>
    )}


{stepTop === 7 && ( 
<Flex direction="row" align="center" gap={4}>
        <Box>
          <Text fontSize="lg" fontWeight="bold" mb={2}>
            Напишите комментарий в чат
          </Text>
          <Textarea
            placeholder="Введите комментарий..."
            borderStyle="dashed"
            borderWidth="2px"
            width="400px"
            height="100px"
          />
        </Box>

        {/* Кнопка "Направить" */}
        <Button
          colorScheme="blue"
          size="md"
          alignSelf="flex-end"
          onClick={() => console.log(`Комментарий: , Банк: `)}

        >
          Направить
        </Button>
      </Flex>
    )}

{stepTop === 5 && ( 
<Flex direction="column" align="center" p={6} gap={8}>
      {/* Ползунок и данные */}
      <Flex direction="column" align="center" width="100%">
        {/* Курс и комиссия */}
        <Flex align="center" justify="space-between" width="100%" mb={4}>
          <Text fontSize="lg" fontWeight="bold">USD 50K</Text>
          <Text fontSize="lg" fontWeight="bold">USD 1M</Text>
        </Flex>

        <Slider
          defaultValue={50000}
          min={50000}
          max={1000000}
          step={5000}
          onChange={handleSliderChange}
          aria-label="payment-slider"
        >
          <SliderMark value={50000} mt="2" ml="-2.5" fontSize="sm">
            USD 50K
          </SliderMark>
          <SliderMark value={1000000} mt="2" ml="-2.5" fontSize="sm">
            USD 1M
          </SliderMark>
          <SliderMark
            value={sliderValue}
            textAlign="center"
            bg="blue.500"
            color="white"
            mt="-10"
            ml="-5"
            w="50px"
            borderRadius="md"
          >
            {`$${(sliderValue / 1000).toFixed(0)}K`}
          </SliderMark>
          <SliderTrack>
            <SliderFilledTrack bg="blue.500" />
          </SliderTrack>
          <SliderThumb />
        </Slider>

        <Flex mt={6} align="center" direction="column">
          <Text fontSize="md" fontWeight="bold">
            Ваша комиссия составит
          </Text>
          <Circle size="50px" borderWidth="2px" borderColor="gray.300" mt={2}>
            <Text fontSize="lg" fontWeight="bold">
              {commission.toFixed(1)}%
            </Text>
          </Circle>
        </Flex>
      </Flex>

      {/* Поле комментария и кнопка */}
      <Flex direction="row" align="center" gap={4} width="100%">
        <VStack align="start" width="100%">
          <Text fontSize="lg" fontWeight="bold" mb={2}>
            Напишите комментарий в чат
          </Text>
          <Textarea
            placeholder="Введите комментарий..."
            value={comment}
            onChange={(e) => setComment(e.target.value)}
            borderStyle="dashed"
            borderWidth="2px"
            width="100%"
            height="100px"
          />
        </VStack>
        <Button
          colorScheme="blue"
          size="lg"
          alignSelf="flex-end"
          onClick={() => console.log(`Комментарий: ${comment}, Сумма: ${sliderValue}, Комиссия: ${commission}`)}
          isDisabled={!comment.trim()} // Блокируем кнопку, если комментарий пустой
        >
          Направить
        </Button>
      </Flex>
    </Flex>
  )}
    {isApproveVisible && (

<Box maxWidth="470px">
<Text color={textColorPrimary} fontSize="sm" fontWeight="bold" textAlign="center" mt={4} mb={4}>
Загрузите подписанную версию договора
</Text>

<FileUpload setIsFileUploaded={setIsFileUploaded} />

<Button
        colorScheme="blue"
        onClick={handleSubmitClick}
        isDisabled={!isFileUploaded}
      >
        Отправить
      </Button>
</Box>

)}



      </Flex>
      ),
    },
    {
      id: 2,
      title: '2 шаг. Согласуйте форму поручения на перевод',
      content: (

        <Flex direction="column" align="center">

<Image src={myImage} alt="Описание изображения" width="200px" />
      <Box m="0 auto 20px">
          <HStack spacing={2}>
            <Tooltip label="Скачать" bg="blue.600" fontSize="sm" borderRadius="md">
              <IconButton icon={<FaDownload />} aria-label="Скачать" onClick={handleDownloadTransfer} />
            </Tooltip>
            <Tooltip label="Посмотреть" bg="blue.600" fontSize="sm" borderRadius="md">
              <IconButton icon={<FaEye />} aria-label="Посмотреть" onClick={onOpen} />
            </Tooltip>
          </HStack>
        </Box>


      <Flex justifyContent="center" gap={4} mt={4}>
+  
      <Button
        leftIcon={<FaEdit />}
        colorScheme="gray"
        variant="outline"
        width="150px"
        onClick={handleEditClick}
      >
        Изменить
      </Button>



      <Button
        leftIcon={<FaCheck />}
        colorScheme="blue"
        width="150px"
        onClick={handleApproveClick}
      >
        Согласовать
      </Button>


    </Flex>

    {isApproveVisible && (

<Box maxWidth="470px">
<Text color={textColorPrimary} fontSize="sm" fontWeight="bold" textAlign="center" mt={4} mb={4}>
Загрузите подписанную версию Поручения
</Text>

<FileUpload setIsFileUploaded={setIsFileUploaded} />

<Button
        colorScheme="blue"
        width="100%"
        ml="auto"
        mr="auto"
        onClick={handleNextStep}
        isDisabled={!isFileUploaded}
      >
        Отправить поручение
      </Button>
</Box>

)}

{isEditVisible && (
    
    <Box maxWidth="470px">
  <Text color={textColorPrimary} fontSize="sm" fontWeight="bold" textAlign="center" mt={4} mb={4}>
    Приложите свою версию поручения
  </Text>

    <FileUpload setIsFileUploaded={setIsFileUploaded}/>
    <Button
        colorScheme="blue"
        width="100%"
        ml="auto"
        mr="auto"
        onClick={handleNextStep}
        isDisabled={!isFileUploaded}
      >
        Отправить поручение
      </Button>


  </Box>

  

)} 


      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent width="90%" maxWidth="900px" pt={8}>
          <ModalCloseButton />
          <ModalBody>
            <TransferTemplate order={order} user={user} />
          </ModalBody>
          <ModalFooter>
          <Button colorScheme="blue" mr={3} onClick={handleDownloadTransfer}>Скачать</Button>
            <Button colorScheme="gray" variant="outline" mr={3} onClick={onClose}>Закрыть</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Flex>

      ),
    },
    {
      id: 3,
      title: '3 шаг. Подтвердите платеж',
      content: (
        <Flex direction="column" alignItems="center">
<Flex justify="center" align="center" py={10}>
      {/* Левый столбец */}

      <Flex direction="column" align="center" mx={5} width="300px">
      <Icon as={BsBank} boxSize="50px" color="blue.500" />
        <Text fontSize="md" mt={2} fontWeight="bold">Оплата счета</Text>

        <Flex mt={4}>
          <VStack align="stretch" spacing={4} mr={4}>
            <Button           fontSize="13px"
          padding="0.5rem 1rem"
          minHeight="unset"
          marginTop="12px"
          position="relative"
          textAlign="left"
          colorScheme="blue" leftIcon={<FaFileDownload fontSize="18px" />}>
              Скачать<br />счёт на оплату
            </Button>
            <Button fontSize="13px"
          padding="0.5rem 1rem"
          minHeight="unset"
          marginTop="12px"
          position="relative" variant="outline" leftIcon={<FaEye />}>
              Посмотреть
            </Button>
          </VStack>
          <VStack align="stretch" spacing={4}>
            <Button           fontSize="13px"
          padding="0.5rem 1rem"
          minHeight="unset"
          marginTop="12px"
          position="relative"
          textAlign="left"
          colorScheme="blue" leftIcon={<FaFileUpload fontSize="18px" />}>
              Загрузить<br />ПП об оплате
            </Button>
            <Button fontSize="13px"
          padding="0.5rem 1rem"
          minHeight="unset"
          marginTop="12px"
          position="relative" variant="outline" leftIcon={<FaEye />}>
              Посмотреть
            </Button>
          </VStack>
        </Flex>
      </Flex>

      {/* Правый столбец */}
      <Flex direction="column" align="center" mx={5} width="300px">
        
        <Icon as={HiOutlineBanknotes} boxSize="50px" color="blue.500" />
        <Text fontSize="md" mt={2} fontWeight="bold">Оплата комиссии</Text>

        <Flex mt={4}>
          <VStack align="stretch" spacing={4} mr={4}>
            <Button fontSize="13px"
          padding="0.5rem 1rem"
          minHeight="unset"
          marginTop="12px"
          position="relative"
          textAlign="left"
          colorScheme="blue"  leftIcon={<FaFileDownload fontSize="18px" />}>
              Скачать<br />счёт на оплату
            </Button>
            <Button fontSize="13px"
          padding="0.5rem 1rem"
          minHeight="unset"
          marginTop="12px"
          position="relative" variant="outline" leftIcon={<FaEye />}>
              Посмотреть
            </Button>
          </VStack>
          <VStack align="stretch" spacing={4}>
            <Button fontSize="14px"
          padding="0.5rem 1rem"
          minHeight="unset"
          marginTop="12px"
          position="relative"
          colorScheme="blue" 
          textAlign="left"
          leftIcon={<FaFileUpload fontSize="18px" />}>
              Загрузить<br />ПП об оплате
            </Button>
            <Button fontSize="13px"
          padding="0.5rem 1rem"
          minHeight="unset"
          marginTop="12px"
          position="relative" variant="outline" leftIcon={<FaEye />}>
              Посмотреть
            </Button>
          </VStack>
        </Flex>
      </Flex>
      </Flex>
      <Flex justify="center" align="center" py={5}>
      <Button colorScheme="blue" size="md" onClick={handleNextStep}>
        Перейти к подтверждению
      </Button>
    </Flex>

    </Flex>   
      ),
    },
    {
      id: 4,
      title: '4 шаг. Подтвердите получение перевода',
      content: (
        <Flex direction="column" alignItems="center">
              <Flex align="center" mt={4}>
      <Icon as={FaCheckCircle} color="green.500" boxSize={6} mr={2} />
      <Text fontSize="md" fontWeight="bold">
        Агент оплатил Платежное поручение
      </Text>
    </Flex>
          <Image src={myImage} alt="Описание изображения" width="200px" />
      <Box m="0 auto 20px">
          <HStack spacing={2}>
            <Tooltip label="Скачать" bg="blue.600" fontSize="sm" borderRadius="md">
              <IconButton icon={<FaDownload />} aria-label="Скачать" onClick={handleDownloadTransfer} />
            </Tooltip>
            <Tooltip label="Посмотреть" bg="blue.600" fontSize="sm" borderRadius="md">
              <IconButton icon={<FaEye />} aria-label="Посмотреть" onClick={onOpen} />
            </Tooltip>
          </HStack>
        </Box>
        <Flex justify="center" align="center" py={5}>
      <Button colorScheme="blue" size="md" onClick={handleSubmitClick}>
        Подтвердить получение
      </Button>
    </Flex>
        </Flex> 
      ),
    },
  ];

  return (
    <VStack spacing={8} align="stretch">
      {steps.map((step) => (
        <Box
          key={step.id}
          p={6}
          borderRadius="md"
          opacity={step.id <= activeStep ? 1 : 0.5}
          bg="gray.100"
          pointerEvents={step.id <= activeStep ? 'auto' : 'none'}
        >
          <Text fontSize="md" fontWeight="bold" mb={2}>{step.title}</Text>
          {step.content}
        </Box>
      ))}
    </VStack>
  );
};

export default TransferInstruction;
